/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import favicon64 from '../images/64x64.png'
import favicon32 from '../images/32x32.png'
import favicon16 from '../images/16x16.png'

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={[
        { rel: "icon", type: "image/png", sizes: "16x16", href: `${favicon16}` },
        { rel: "icon", type: "image/png", sizes: "32x32", href: `${favicon32}` },
        { rel: "shortcut icon", type: "image/png", href: `${favicon64}` },
      ]}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: 'Wino Prinz Stefan wyróżnia się zharmonizowanym smakiem, aromatycznym zapachem i łagodną kwasowością',
        },
        {
          name: `keywords`,
          content: 'Prinz Stefan, Wina Prinz Stefana, Wina Ziołowe, Wina, wino, Wina austriackie, Najwyższej jakości  wina, Wina gronowe, Rodzina Sareckich, Wina Rodziny Sareckich, Wina Prinz Stefan, Wino wytrawne, Wino półwytrawne, Wino półsłodkie, Wino białe, Wino czerwone, Wino białe półwytrawne, Wino białe wytrawne, Wino białe półsłodkie, Wino czerwone wytrawne, Wino czerwone, Półsłodkie, Wino czerwone półwytrawne, Wino czerwone, Intakt ziół',
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: 'Wino Prinz Stefan wyróżnia się zharmonizowanym smakiem, aromatycznym zapachem i łagodną kwasowością',
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: 'Wino Prinz Stefan wyróżnia się zharmonizowanym smakiem, aromatycznym zapachem i łagodną kwasowością',
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `pl`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO

import React from "react"
import "./../scss/main.scss"
import { StaticQuery, graphql } from "gatsby"
import Header from "./Header/Header"
import Footer from "./Footer/Footer"
import { createGlobalStyle } from 'styled-components';

import fontCalistoTtf from '../fonts/CalistoMT.ttf';
import fontCalistoWoff2 from '../fonts/CalistoMT.woff2';
import fontCalistoWoff from '../fonts/CalistoMT.woff';

const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: 'FontCalisto';
    src: 
    url('${fontCalistoTtf}') format('ttf'),
    url('${fontCalistoWoff2}') format('woff2'),
    url('${fontCalistoWoff}') format('woff');
    font-weight: 700;
    font-style: normal;
}
`

const Layout = ({ children, route }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        allWordpressAcfOptions {
          nodes {
            options {
              footer_text
              logo_in_menu {
                localFile {
                  publicURL
                }
              }
              logo_in_menu_mobile {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    `}
    render={ data => (
      <>
        <GlobalStyle />
        <Header logo={ data.allWordpressAcfOptions.nodes[0].options.logo_in_menu.localFile.publicURL } 
                smallLogo={ data.allWordpressAcfOptions.nodes[0].options.logo_in_menu_mobile.localFile.publicURL }
                route={route} />

          <main>{children}</main>
          <footer>
            <Footer />
          </footer>
      </> )} />
  )


export default Layout

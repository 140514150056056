import React from 'react';

const Copyright = ( props ) => (
    
    <div className="copyright-box">
        {/* <span>{ props.data.footer_text }</span> */}
        <div dangerouslySetInnerHTML={ { __html: props.data.footer_text } } />
    </div>
)


export default Copyright;